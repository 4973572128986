import routerConfig from "srs.sharedcomponents/lib/esm/components/config/routes";

const routesConfig = {
  authenticationRoutes: {
    ...routerConfig.authenticationRoutes,
    "/login": {
      path: "/login",
      component: "ChooseLogin",
      pageTitle: "Login",
    },
    "/retail-login": {
      path: "/retail-login",
      component: "LoginPage",
      pageTitle: "txtLogin",
    },
  },
  publicRoutes: {
    "/service-support": {
      path: "/service-support",
      component: "ServiceSupport",
      pageTitle: "Service Support",
    },
    "/ev-servicing": {
      path: "/ev-servicing",
      component: "EvServicing",
      pageTitle: "Ev Servicing",
      modelKey: "ev-servicing",
      modelName: "content-page",
    },
    "/browser-suppliers": {
      path: "/browser-suppliers",
      component: "BrowserSuppliers",
      pageTitle: "Browser Suppliers",
    },
    "/electric-vehicle-competency": {
      path: "/electric-vehicle-competency",
      component: "ElectricVehicleCompetency",
      pageTitle: "Electric Vehicle Competency",
      modelKey: "electric-vehicle-competency",
      modelName: "content-page",
    },
    "/equipment-assessment": {
      path: "/equipment-assessment",
      component: "EquipmentAssessment",
      pageTitle: "Equipment Assessment",
      modelKey: "equipment-assessment",
      modelName: "content-page",
    },
    "/new-trending-initiatives": {
      path: "/new-trending-initiatives",
      component: "NewTrendigAndInitiatives",
      pageTitle: "New Trending Initiatives",
      modelKey: "equipment-assessment",
      modelName: "content-page",
    },
    "/facilities-planning": {
      path: "/facilities-planning",
      component: "FacilitiesPlanning",
      pageTitle: "Facilities Planning",
      modelKey: "facilities-planning",
      modelName: "content-page",
    },
    "/finance-terms": {
      path: "/finance-terms",
      component: "FinanceTerms",
      pageTitle: "Finance Terms",
      modelKey: "facilities-planning",
      modelName: "content-page",
    },
    "/tool-storage": {
      path: "/tool-storage",
      component: "ToolStorage",
      pageTitle: "Tool Storage",
      modelKey: "facilities-planning",
      modelName: "content-page",
    },
  },
};

export default routesConfig;
