/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { i18nNamespaces as namespace } from 'srs.sharedcomponents/lib/esm/i18n/i18n'
import { IDropDownOption } from 'srs.sharedcomponents/lib/esm/models/srs.formDropdownOption.model'
import { useAppSelector } from "srs.sharedcomponents/lib/esm/redux/hooks";

import {
  IFacetedItem,
  IFacetedSearchRequest,
} from 'srs.sharedcomponents/lib/esm/models/facetedSearch/facetedSearch.model'
import {
  getFacetedOptions,
  getFacetedConfigurations,
} from 'srs.sharedcomponents/lib/esm/redux/slices/facetedSearchSlice'
import {
  getFacetedProducts,
  updateRequest,
  clearState as clearProducts,
  updateFromMainZone,
} from 'srs.sharedcomponents/lib/esm/redux/slices/facetedProductListSlice'
import * as S from './styles'
import { useDispatch } from 'react-redux'
import { FacetedSearchType } from 'srs.sharedcomponents/lib/esm/utils/enums'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useTranslate } from 'srs.sharedcomponents/lib/esm/hooks/useTranslate';
import { useTranslation } from 'react-i18next'
import { FormInputDropdown } from 'srs.sharedcomponents/lib/esm/components/srs.formInputDropdown.component'

interface IFormSchema {
  [key: string]: string
}
interface IFacetedSearchProps {
  mainZone?: boolean
  isKeyFinder?: boolean
}
interface LevelInfo {
  level: number;
  name: string;
}
interface FacetConfiguration {
  levelInfos: LevelInfo[];
  rootTag: string;
}


const FacetedSearch: React.FC<IFacetedSearchProps> = ({ mainZone, isKeyFinder }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { facetItems, facetConfiguration } = useAppSelector((state) => state.facetedSearch)
  const [resetInputValues, setResetInputValues] = useState<boolean>(false)
  const { fromMainZone, request: requestFromMainZone } = useAppSelector(
    (state) => state.facetedProductList,
  )
  const [formInputs, setFormInputs] = useState<any>(undefined)
  const { translate } = useTranslate()
  const getOptions = useCallback(
    (level: number) => {
      let filteredItems = facetItems.filter((levelItem) => levelItem.level === level)
      switch (level) {
        case 0:
          filteredItems = filteredItems.sort((a, b) => b.name.localeCompare(a.name))
          break
        case 1:
        case 2:
        case 3:
          filteredItems = filteredItems.sort((a, b) => a.name.localeCompare(b.name))
          break
        default:
          break
      }

      const dropdownOptions = filteredItems.map((levelItem) => {
        return { label: levelItem.name, value: levelItem.name }
      })
      return [
        {
          label: t(facetConfiguration?.levelInfos[level].name ?? '', {
            ns: namespace.coreSrsPrimary,
          }),
          value: '',
        },
        ...dropdownOptions,
      ] as IDropDownOption[]
    },
    [facetItems],
  )

  const getDefaultValue = useCallback(
    (levelName: string) => {
      let defaultValue = getValues(levelName as `${number}.${string}`)
      return defaultValue
    },
    [facetItems, resetInputValues],
  )

  const schema = yup.object().shape({
    facetItems: yup.array().of(
      yup.object().shape({
        rootTag: yup.string(),
        level: yup.number(),
        name: yup.string(),
      }),
    ),
  })

  const methods = useForm<IFormSchema[]>({
    defaultValues: formInputs,

    resolver: yupResolver(schema),
    mode: 'all',
  })

  const { control, getValues, setValue, reset } = methods

  const submitForm = useCallback(
    (e) => {
      dispatch(clearProducts())
      setResetInputValues(false)
      let clear = false
      const request = facetConfiguration?.levelInfos.map((level) => {
        let name = getValues(level.name as `${number}` | `${number}.${string}`)
        if (clear) {
          name = ''
        } else if (level.name === e.name) {
          name = e.value
          clear = true
        }
        return {
          level: level.level,
          name,
          rootTag: facetConfiguration.rootTag,
        }
      }) as IFacetedItem[]

      dispatch(updateRequest(request))
      if (mainZone) {
        dispatch(updateFromMainZone(true))
        history.push('/facetedSearch')
      }
      if (!mainZone && request && !isKeyFinder) {
        dispatch(clearProducts())
        dispatch(getFacetedProducts({ facetItems: request, pageNumber: 0, pageSize: 9 }))
      }
      if (facetConfiguration?.type === FacetedSearchType.Dynamic) {
        dispatch(getFacetedOptions({ facetItems: request } as IFacetedSearchRequest))
      }
    },
    [facetConfiguration, facetItems, isKeyFinder],
  )

  const handleSelectChange = (e: { name?: string; value: string }) => {
    const modifiedField =
      e.value === 'All Years' || e.value === 'All Makes' || e.value === 'All Models'
        ? { value: '', name: e.name }
        : e
    submitForm(modifiedField)
  }

  useEffect(() => {
    if (!fromMainZone) dispatch(clearProducts())
  }, [])

  useEffect(() => {
    if (!facetConfiguration) {
      dispatch(getFacetedConfigurations())
    }
    //dispatch(getFacetedOptions({} as IFacetedSearchRequest))
  }, [facetConfiguration])

  useEffect(() => {
    if (facetConfiguration && facetItems.length > 0) {
      let request
      if (fromMainZone) {
        request = requestFromMainZone
        if (requestFromMainZone) {
          facetConfiguration?.levelInfos.forEach((config, index) => {
            setValue(
              config.name as `${number}` | `${number}.${string}`,
              Object.values(requestFromMainZone)[index].name as string,
            )
          })
        }
        dispatch(updateFromMainZone(false))
      } else {
        request = facetConfiguration?.levelInfos.map((level) => {
          return {
            level: level.level,
            name: null,
            rootTag: facetConfiguration.rootTag,
          } as IFacetedItem
        })

        dispatch(updateRequest(request))
      }
      if (!mainZone && request) {
        dispatch(clearProducts())
        dispatch(getFacetedProducts({ facetItems: request, pageNumber: 0, pageSize: 9 }))
      }
    }
  }, [facetConfiguration])

  const createFieldsAndGetOptions = async () => {
    if (facetConfiguration) {
      setFormInputs(
        facetConfiguration?.levelInfos.reduce<IFormSchema[]>((acc, cur) => {
          return [...acc, { [cur.name]: cur.name }]
        }, []),
      )

      const facetedItemsRequest = facetConfiguration.levelInfos.map((level) => {
        return {
          rootTag: facetConfiguration.rootTag,
          name: null,
          level: level.level,
        } as IFacetedItem
      })
      dispatch(getFacetedOptions({ facetItems: facetedItemsRequest } as IFacetedSearchRequest))
    }
  }
  useEffect(() => {
    if (!fromMainZone) createFieldsAndGetOptions()
  }, [facetConfiguration])

  const createRequest = (facetConfiguration: FacetConfiguration) => {
    return facetConfiguration?.levelInfos.map((level) => {
      return {
        level: level.level,
        name: getValues(level.name as `${number}` | `${number}.${string}`),
        rootTag: facetConfiguration.rootTag,
      } as IFacetedItem;
    });
  };

  const customSearch = () => {
    if (facetConfiguration) {
      const request = createRequest(facetConfiguration);
      if (request?.[2]?.name) {
        dispatch(updateRequest(request));
        dispatch(getFacetedProducts({ facetItems: request, pageNumber: 0, pageSize: 9 }));
      }
    } else {
      console.warn('facetConfiguration is undefined');
    }
  };




  function handleResetInputs(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    dispatch(clearProducts());
    reset();
    setResetInputValues(true);

    const request = facetConfiguration?.levelInfos.map((level) => {
      return {
        level: level.level,
        name: undefined,
        rootTag: facetConfiguration.rootTag,
      } as IFacetedItem;
    });

    dispatch(updateRequest(request));

    if (!mainZone && request) {
      dispatch(clearProducts());
      dispatch(getFacetedProducts({ facetItems: request, pageNumber: 0, pageSize: 9 }));
    }

    if (facetConfiguration?.type === FacetedSearchType.Dynamic) {
      dispatch(getFacetedOptions({ facetItems: request } as IFacetedSearchRequest));
    }

    createFieldsAndGetOptions();
  }

  return (
    <div>
      <S.SelectsForm onSubmit={submitForm} mainImage={mainZone} isKeyFinder={isKeyFinder} >
        {!isKeyFinder && (
          <S.Text mainImage={mainZone}> {translate('FacetedSearchBrowseText')} </S.Text>
        )}
        {
          facetConfiguration?.levelInfos?.map((level) => (
            <FormInputDropdown
              key={level.name}
              control={control}
              label={translate(level.name)}
              options={getOptions(level.level)}
              name={level.name}
              onChange={handleSelectChange}
              id={level.name}
              selectedValue={getDefaultValue(level.name)}
              resetValue={resetInputValues}
              isKeyFinder={isKeyFinder}
            />
          ))}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {!mainZone && (
            <S.ResetButton onClick={handleResetInputs}>
              {translate(`btnResetTooltip`)}
            </S.ResetButton>
          )}
          {
            isKeyFinder && (
              <div className="hideFocus " >
                <button
                  style={
                    {
                      color: '#adb5bd',
                      marginRight: '5px',
                      cursor: 'pointer',
                    }
                  }
                  className="bg-transparent"
                  id="btnsubmit"
                  type="button"
                  onClick={customSearch}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            )
          }
        </div>
      </S.SelectsForm>
    </div>
  )
}

export default FacetedSearch
