/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useTranslate } from 'srs.sharedcomponents/lib/esm/hooks/useTranslate';
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import * as S from './styles'
import { useEffect, useState } from 'react'
import ProductListComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.productList.component'
import FacetedSearch from '../FacetedSearch/FacetedSearch'
import { stringToBoolean } from 'srs.sharedcomponents/lib/esm/utils/helpers'
import RefineYourSearch from 'components/RefineYourSearch/RefineYourSearch';

function KeyFinder() {
  const { translate } = useTranslate()
  const [keyPartNumberSearchValue, setKeyPartNumberSearchValue] = useState('')
  const [vinSearchValue, setVinSearchValue] = useState('')
  const [keyPartValue, setKeyPartValue] = useState('')
  const [vinValue, setVinValue] = useState('')
  const [searchPressed, setSearchPressed] = useState(false)
  const [keyPartFocused, setKeyPartFocused] = useState(false)
  const [vinFocused, setVinFocused] = useState(false)
  const fullWidthLayout = stringToBoolean(process.env.REACT_APP_FULL_WIDTH_LAYOUT)

  useEffect(() => {
    if (searchPressed) {
      clearForm()
    }
  }, [searchPressed, keyPartNumberSearchValue, vinSearchValue])

  const { reset } = useForm({
    defaultValues: {
      make: '',
      model: '',
      year: '',
    },
    mode: 'onBlur',
  })

  const validationSchema = Yup.object().shape({
    search: Yup.string(),
    vin: Yup.string().when('search', {
      is: (search: string | undefined) => !search,  // Explicitly type 'search'
      then: Yup.string().min(17, 'VIN must be at least 17 characters long'),
      otherwise: Yup.string(),
    }),
    make: Yup.string(),
    model: Yup.string(),
    year: Yup.string(),
  });


  const handleSearch = async () => {
    try {
      await validationSchema.validate({
        search: keyPartNumberSearchValue,
        vin: vinSearchValue,
      })
      if (
        (keyPartNumberSearchValue && keyPartNumberSearchValue.length > 3) ||
        (vinSearchValue && vinSearchValue.length > 16)
      ) {
        setKeyPartValue(keyPartNumberSearchValue)
        setVinValue(vinSearchValue)
        setSearchPressed(true)
      }
    } catch (error: any) { }
  }

  const clearForm = () => {
    setKeyPartNumberSearchValue('')
    setVinSearchValue('')
    setSearchPressed(false)
    reset({
      make: '',
      model: '',
      year: '',
    })
  }

  const handleKeyPartFocus = () => {
    setKeyPartFocused(true)
  }

  const handleVinFocus = () => {
    setVinFocused(true)
  }

  const handleKeyPartBlur = () => {
    setKeyPartFocused(false)
  }

  const handleVinBlur = () => {
    setVinFocused(false)
  }

  return (
    <Container fluid={fullWidthLayout} style={{ display: 'flex', justifyContent: 'space-between' }} className="item__details">
      <S.container>
        <Col>
          <S.logo className="pb-3">{translate(`serviceSupport.menu2.title`)}</S.logo>
          <form className="form-group m-0 text-right w-full">
            <div style={{ marginBottom: '-5px' }} className="input-group mr-sm-2 hideFocus">
              <div id="key-part-number" className="input-group mb-2 mr-sm-2 hideFocus">
                <label htmlFor="part-number" className="sr-only">
                  {translate(`serviceSupport.menu2.item1`)}
                </label>
                <input
                  style={{ borderRadius: '4px' }}
                  type="text"
                  className="form-control form-control-lg"
                  id="part-number"
                  name="part-number"
                  value={keyPartNumberSearchValue}
                  onChange={(e) => setKeyPartNumberSearchValue(e.target.value)}
                  placeholder={translate(`serviceSupport.menu2.item1`)}
                  onClick={() => setKeyPartNumberSearchValue('')}
                  onFocus={handleKeyPartFocus}
                  onBlur={handleKeyPartBlur}
                />
                <div className="header__input-group-append">
                  <button
                    style={{ color: keyPartFocused ? 'grey' : '#adb5bd' }}
                    className="input-group-text btn border-rounded-right-10 border-left-0"
                    id="btnsubmit"
                    type="button"
                    onClick={handleSearch}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </div>

              <div id="vin" className="input-group mb-2 mr-sm-2 hideFocus">
                <label htmlFor="vin" className="sr-only">
                  {translate(`serviceSupport.menu2.item2`)}
                </label>
                <input
                  style={{ borderRadius: '4px' }}
                  type="text"
                  className="form-control form-control-lg"
                  id="vin"
                  name="vin"
                  value={vinSearchValue}
                  onChange={(e) => setVinSearchValue(e.target.value)}
                  placeholder={translate(`serviceSupport.menu2.item2`)}
                  onFocus={handleVinFocus}
                  onBlur={handleVinBlur}
                />
                <div className="header__input-group-append">
                  <button
                    style={{ color: vinFocused ? 'grey' : '#adb5bd' }}
                    className="input-group-text btn bg-white border-rounded-right-10 border-left-0"
                    id="vinsubmit"
                    type="button"
                    onClick={handleSearch}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </div>
            </div>
            <hr style={{ marginTop: '17px' }} />
            <S.dropdownContainer className="input-group mr-sm-2 hideFocus">
              <FacetedSearch isKeyFinder={true} />
            </S.dropdownContainer>
            <hr style={{ margin: '0px' }} />
          </form>
          <Container>
            <S.dropdownContainer className="input-group mr-sm-2 hideFocus">
              <RefineYourSearch isKeyFinder={true} />
            </S.dropdownContainer>
          </Container>
        </Col>
      </S.container>
      <Container>
        {(keyPartValue || vinValue) && !searchPressed && (
          <ProductListComponent keyPartSearchValue={keyPartValue} vinSearchValue={vinValue} />
        )}
      </Container>
    </Container>
  )
}

export default KeyFinder
